import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import getDropDownItem from 'utils/dropDownControl';
import Layout from 'components/Layout';
import Banner from 'components/common/Banner';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';
import RelatedProducts from 'components/RelatedProducts';
import ExpertiseArticles from 'components/ExpertiseArticles';
import CategoriesNav from 'components/common/CategoriesNav';
import {
  generateLocationQueryString,
  getLocationQS,
  getLocationQueryStringParam,
} from 'utils/browser';
import Overlay from 'components/common/Overlay';
import Tips from 'components/common/Tips';

import 'styles/main.scss';
import './article-listing.scss';

type TProps = {
  pageContext: {
    link: string;
    articlesMatrix: ArticleTypes.IArticle[];
    xOrigin: string;
  };
  data: IArticleListingPageData;
};

interface IArticleListingPageData extends PageContent.IDefaultLayoutProps {
  page: ListingPage.TListingPage;
  relatedProducts: { nodes: ProductTypes.IProduct[] };
  umbracoTips: AllTipsContent.TUmbracoTips;
}

const ArticleListingPage: FC<TProps> = ({
  pageContext: { articlesMatrix, xOrigin },
  data: {
    page,
    relatedProducts = [],
    umbracoTips = [],
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<ArticleTypes.IArticle[]>(articlesMatrix);
  const fetchArticles = async () => {
    setLoading(true);

    const tipsTags = [...getLocationQueryStringParam('tipTags')].join(',');
    const masterTags = [...page.masterTag.map(({ id }) => id)].join(',');
    const fetchResponse = await fetch(
      `/api/filter-articles?masterTags=${masterTags}&lang=${siteSettings.lang}&tipsTags=${tipsTags}`,
      {
        method: 'GET',
        headers: { 'x-origin': xOrigin },
      }
    );

    if (fetchResponse.ok) {
      const response = await fetchResponse.json();
      setArticles(response);
    } else {
      setArticles([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    const currentFilters = getLocationQS();
    if (currentFilters && currentFilters.length > 0) {
      fetchArticles();
    }
  }, [getLocationQS()]);

  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    link,
    showTips,
    switchTipTitle,
  } = page;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <Banner
        img={page.image}
        alt={page.altImage}
        title={{
          regularText: page.titleRegular,
          boldText: page.titleBold,
        }}
        text={page.description}
      />

      <Container className="dt-article-list" fluid>
        <CategoriesNav
          selectedTag={page.masterTag[0]}
          categories={page.subCategories}
          btnDropdown={siteSettings.chooseCategoryText ?? 'Choose a category'}
          selectedDropdown={page.name}
        />

        {!!Number(showTips) && (
          <>
            <Tips
              title={{
                regularText: page.tipsTitle.regularText,
                boldText: page.tipsTitle.boldText,
              }}
              data={umbracoTips?.tipsBlocks?.map(
                ({ properties: { titleBold, titleRegular, iconImage, tagNodes } }) => ({
                  name: Number(switchTipTitle) ? titleBold : titleRegular,
                  icon: getDropDownItem(iconImage) || '',
                  link: generateLocationQueryString(
                    'tipTags',
                    tagNodes.map(({ id }) => id).join(',')
                  ),
                })
              )}
              btn={{ btnText: umbracoTips?.buttonText, btnLink: umbracoTips?.link }}
            />
            <hr className="dt-hr-wide" />
          </>
        )}
        {articles.length ? (
          <ExpertiseArticles
            title={{
              regularText: page.articlesTitle.regularText,
              boldText: page.articlesTitle.boldText,
            }}
            articles={articles}
            btn={{ btnText: page.buttonText || '' }}
            showingOfArticles={siteSettings.showingOfArticles}
          />
        ) : (
          <div className="dt-no-result-text">{siteSettings.noResults}</div>
        )}
      </Container>

      {!!relatedProducts.nodes?.length && (
        <RelatedProducts
          products={relatedProducts.nodes}
          title={{
            regularText: page.productsTitle.regularText,
            boldText: page.productsTitle.boldText,
          }}
        />
      )}
      <DidYouKnowLoadableWrapper didYouKnow={page.didYouKnow} mask />
      <Overlay visible={loading} />
    </Layout>
  );
};

export const query = graphql`
  query ArticleListingPageQuery($link: String!, $relatedProductsSku: [String] = "", $lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      lang
      showingOfArticles
      noResults
      chooseCategoryText
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    page: umbracoArticleListingPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      description
      name
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
      titleBold
      titleRegular
      showTips
      switchTipTitle
      subCategories {
        id
        name
        title
        link {
          url
        }
      }
      masterTag {
        id
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        ariaLabel
        imageAlt
        sectionLandmark
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      image {
        fluid {
          srcSet
          base64
        }
        fallbackUrl
      }
      tipsTitle {
        boldText
        regularText
      }
      articlesTitle {
        boldText
        regularText
      }
      productsTitle {
        boldText
        regularText
      }
      buttonText
    }
    umbracoTips(lang: { eq: $lang }) {
      tipsBlocks {
        properties {
          switchTitles
          titleRegular
          titleBold
          iconImage
          tagNodes {
            id
          }
        }
      }
      link
      buttonText
    }
    relatedProducts: allUmbracoProduct(
      filter: { sku: { in: $relatedProductsSku }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        link
        size
        productColor
        cardImage {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 300, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        name
        recognisableFeature
        descriptionLong
        descriptionShort
        titleBrandbank
      }
    }
  }
`;

export default ArticleListingPage;
